import './floatLabel.scss'
import {textAreaAutoGrow} from './autoGrow'

type LabelElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement

export const floatLabels = (() => {
    const initForm = (form: HTMLElement) => {
        textAreaAutoGrow(form)

        form.querySelectorAll<LabelElement>('input, textarea, select').forEach((element) => {
            initSingle(element)
        })
    }

    const initSingle = (element: LabelElement) => {
        const parent = getFieldWrapper(element)

        if (!parent || element.type === 'hidden' || element.type === 'checkbox' || element.type === 'radio') {
            if (element.id != 'billing_country' || !parent) {
                return;
            }
        }

        parent.classList.add('floatLabelParent')

        if (element instanceof HTMLTextAreaElement) {
            parent.classList.add('floatLabelParent--textarea')
        }

        element.addEventListener('focus', (e) => updateLabelClass(e.currentTarget as LabelElement))
        element.addEventListener('blur', (e) => updateLabelClass(e.currentTarget as LabelElement))
        element.addEventListener('change', (e) => updateLabelClass(e.currentTarget as LabelElement))
        element.addEventListener('keypress', (e) => updateLabelClass(e.currentTarget as LabelElement))
        element.addEventListener('input', (e) => updateLabelClass(e.currentTarget as LabelElement))

        jQuery(element).on('change', () => {
            updateLabelClass(element)
        })

        jQuery(element).on('input', () => {
            updateLabelClass(element)
        })

        updateLabelClass(element)
    }

    const updateLabelClass = (element: LabelElement) => {
        const wrapper = getFieldWrapper(element)

        if (!wrapper) {
            return
        }

        const hasValue = fieldHasValue(element)
        wrapper.classList.toggle('floatLabelParent--hasValue', hasValue)
    }

    const getFieldWrapper = (field: LabelElement): HTMLElement | null => {
        return field.closest('.gfield, td.value, div.quantity, div.coupon, p.form-row, div.productDetail__variation')
    }

    const fieldHasValue = (field: LabelElement) => {
        const focussed = document.activeElement === field
        return focussed || !!field.value.trim() || !!field.getAttribute('placeholder')
    }

    return {
        initForm,
        initSingle,
        updateLabelClass,
    }
})()
jQuery(document).ajaxComplete(function () {
    setTimeout(() => {
        document.querySelectorAll<LabelElement>('.woocommerce-billing-fields input').forEach((element) => {
            floatLabels.updateLabelClass(element)
        })
    }, 1)
})
